import React from 'react'
import { graphql } from 'gatsby'
import { Text, Page, SEO, View } from 'lib'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { BlogItem } from './_card'


export default function Blog({ data }) {
  const styles = useStyles()
  const content = data.allMarkdownRemark.edges.map(({ node }) => (<BlogItem node={node} key={node.id}/>))
  return (
    <Page>
      <SEO title={'Blog'} description={'Our latest thinking about building great products.'}/>
      <View style={styles.verticalSpace}/>
      <Text variant='h4' text={'Inside TechnoQuest'}/>
      <Text text={'Our latest thinking about building great products.'}/>
      <View style={styles.verticalSpace}/>
      <Grid container spacing={6} direction={'row'} alignItems={'stretch'} className={styles.listWrapper}>
        {content}
      </Grid>
    </Page>
  )
}

export const query = graphql`
query {
  allMarkdownRemark
  (sort: { fields: [frontmatter___date], order: DESC })
  {
    totalCount
    edges {
      node {
        id
        excerpt(pruneLength: 400)
        frontmatter {
          title
          subtitle
          image {
            childImageSharp {
                sizes(maxWidth: 630) {
                    ...GatsbyImageSharpSizes
                }
            }
          }
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
}
`

const useStyles = makeStyles(theme => ({
  listWrapper: {
    marginBottom: theme.spacing(4),
  },
  stretch: {
    height: '100%',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  verticalSpace: {
    height: theme.spacing(2),
  },
  previewTitleText: {
    fontSize: 28,
    fontWeight: 700,
    backgroundImage: theme.palette.gradient,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
  },
  previewInfoText: {
    fontSize: 14,
    color: '#777',
  },
  previewSubText: {
    fontSize: 16,
    color: '#333',
  },
  image: {
    height: 160,
  },
  profileImage: {
    marginRight: theme.spacing(1),
  },
  profileText: {
    fontSize: 14,
  },
  clickable: {
    height: '100%',
    display: 'inline-block',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
