/* eslint-disable no-confusing-arrow */
import React, { useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import Img from 'gatsby-image'
import { View, Text } from 'lib'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import Tilt from 'react-tilt'


export function BlogItem(props) {
  let isBrowser = false // prevents gatsby from trying to build with inexisting stuff
  useEffect(() => {
    isBrowser = true
  }, [])
  const meta = props.node.frontmatter
  const excerpt = props.node.excerpt
  const styles = useStyles()
  const titleElementId = `blogTitle_${meta.title}`
  const height = isBrowser && document.getElementById(titleElementId) && document.getElementById(titleElementId).offsetHeight
  const titleHeightLines = height ? height / 42 : 1 // 42 (or forty-two), is the Answer to the Ultimate Question of Life, the Universe and Everything.
  const maxLen = 270 - (70 * titleHeightLines)
  const truncate = (input) => input.length > maxLen ? `${input.substring(0, maxLen)}...` : input
  const preview = truncate(excerpt)

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Tilt className={styles.card} options={{ scale: 1.05, max: 6, speed: 2000 }}>
        <Link to={`/${props.node.fields.slug}`} className={styles.link}>
          <View className={`${styles.clickable} ${styles.stretch}`}>
            <View className={`${styles.stretch} ${styles.content}`}>
              <Img sizes ={meta.image.childImageSharp.sizes} className={styles.image} alt={meta.title}/>
              <View style={styles.contentTop}>
                <div id={titleElementId}><Text variant={'h5'} className={styles.previewTitleText} text={meta.title}/></div>
                <Separator/>
                <View style={styles.previewWrapper}>
                  <Text className={styles.previewSubText} text={preview}/>
                </View>
              </View>
              <View style={styles.contentBottom}>
                <Text className={styles.readMoreText} text={'Read more'}/>
              </View>
            </View>
          </View>
        </Link>
      </Tilt>
    </Grid>
  )
}

function Separator() {
  const styles = useStyles()
  return (
    <View style={styles.separator}></View>
  )
}

const useStyles = makeStyles(theme => ({
  stretch: {
    height: '100%',
  },
  card: {
    height: '100%',
    flex: 1,
    display: 'flex',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  previewTitleText: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  previewInfoText: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  previewSubText: {
    fontSize: 16,
  },
  image: {
    height: 160,
  },
  profileImage: {
    marginRight: theme.spacing(1),
  },
  profileText: {
    fontSize: 14,
  },
  clickable: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTop: {
    flex: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  separator: {
    height: 1,
    backgroundColor: '#ddd',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  contentBottom: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
    display: 'flex',
  },
  readMoreText: {
    fontStyle: 'italic',
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: '400',
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flex: 1.0,
  },
}))
